.horizontalGenericForm {
  display: inline-flex;
  align-items: center;

  .genericForm {
    &-group {
      &__label {
        min-width: 30%;
        margin-bottom: unset;
        margin-right: 0.5rem;
      }
      &__message {
        margin-left: 1rem;
      }
    }
  }
}
.isSingle {
  .css-yk16xz-control {
    min-height: unset !important;
    height: 2.2rem;
    background-color: $rigo-input-bg-color !important;
    color: #444;
  }
  .css-1wa3eu0-placeholder {
    line-height: 1;
  }
  .css-109onse-indicatorSeparator {
    margin-right: 4px;
  }
  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    padding: 4px 8px 4px 0px;
    // padding: unset;
  }
}
.css-1pahdxg-control {
  box-shadow: 0 0 0 0.2px #{$btn-primary-color},
    0 0 0 2.5px rgba($btn-primary-color, 0.4) !important;
}
.isMulti {
  .css-1hb7zxy-IndicatorsContainer {
    align-items: flex-start !important;
    padding-right: 2px;

    div {
      padding: 7px 4px;
    }
  }
}

.genericForm {
  &-group {
    width: 100%;
    // margin-right: 1rem;

    .disable-input {
      background-color: #eee;
    }
    &__upload {
      // width: 200px;
      // background: #f5f7fa;
      // height: 175px;
      // border: 2px solid #a6a9b0;
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      border-radius: 4px;
      // margin: 10px 0;
      position: relative;

      padding: 15px;
      background: #edf0f4;
      border: none;

      .error__message {
        margin-left: unset !important;
      }
      &-text-section {
        display: flex;
        flex-direction: column;

        .text1 {
          font-size: 14px;
          color: #4c4e53;
          font-weight: 500;
        }
        .text2 {
          font-size: 12px;
          color: #999;
          font-weight: 400;
          line-height: 16px;
        }
      }

      &-btn {
        padding: 8px 20px;
        background: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        color: #595959;
        margin: 10px 0;
      }
      &-label {
        color: #000000d9;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        padding: 0 2px;
      }
      &-input {
        cursor: pointer;
        position: absolute;
        text-indent: -125px;
        color: transparent;
        z-index: 3;
        /* padding-top: 40px; */

        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        font-family: "Open Sans", sans-serif;
        &:hover {
          box-shadow: 0 0.1rem 0.25rem rgba(#222, 0.2);
        }

        &:focus,
        &:active {
          outline: transparent;
        }
      }
    }

    // //For removing autoselect inside icon padding
    //.css-tlfecz-indicatorContainer {
    //   padding: unset;
    // }
    &.error {
      .genericForm {
        &-group {
          &__input {
            border-color: #{$color-red};
          }
          &__textarea {
            border-color: #{$color-red};
          }
          &__date {
            border-color: #{$color-red};
          }
          &-custom-time {
            border-color: #{$color-red};
          }
          &__time {
            border-color: #{$color-red};
          }
          &__label {
            color: #{$color-red};
          }

          &__message {
            color: #{$color-red};
            margin-top: 0.2rem;
            font-weight: 400 !important;
            display: block;
            font-size: 12px;
          }
        }
      }
      .ydm-input-group {
        border-color: #{$color-red};
      }
      .bjPjlv {
        border-color: #{$color-red} !important;
      }
    }
    &__inner-label {
      padding: 0 2px 0 12px;
      height: 100%;
      font-size: 14px;
      color: #2c2d2c;
      display: grid;
      place-items: center;
    }
    &__select {
      border: none;
      outline: none;
      flex-grow: 1;
      height: 100%;
      padding: 0 12px 0 2px;
      font-size: 14px;
      margin-top: 2px;
      appearance: none;
      option {
        font-size: 14px;
        padding: 5px;
      }
      &-container {
        width: 100%;
        position: relative;
        // padding: 10px 12px;
        padding: 2px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border: thin solid $color-grey-light;
        border-radius: $default-border-radius;
        transition: box-shadow 0.2s ease 0s, border-color 0.2s ease 0s;
        outline: none;
        background: #fff;
        font-size: 14px;
        color: #2c2d2c;
        height: $input-height;
        background-color: $rigo-input-bg-color;
        &:focus-within {
          @include inputFocusStyle;
        }
      }
    }
    &__label {
      @include label;
    }

    &__input {
      width: 100%;
      outline: none;
      padding: 8px 12px;
      background: #fff;
      font-size: 14px;
      color: #444;
      // height: $input-height;
      border-radius: $default-border-radius;
      // border-width: 1px;
      // border-style: solid;
      // border-color: $rigo-input-border-color;
      border: 1px solid $color-grey-light;
      background-color: $rigo-input-bg-color;
      transition: box-shadow 0.2s ease 0s, border-color 0.2s ease 0s;
      &:focus {
        @include inputFocusStyle;
      }
      &::-webkit-input-placeholder {
        @include placeholderText();
      }
    }

    &__textarea {
      width: 100%;
      min-height: 100px;
      padding: 10px 12px;
      border-radius: 4px;
      outline: none;
      border: 1px solid #cacaca;
      background: $rigo-input-bg-color;
      font-size: 1rem;
      color: #444;
      font-family: $rigo-font-stack;
      //   &:focus {
      //     outline: none;
      //     // box-shadow: 0 .5rem 1rem rgba(#000, .1);
      //     border: 1px solid #40c5ff;
      //   }
      &:focus {
        @include inputFocusStyle;
      }
      &::-webkit-input-placeholder {
        @include placeholderText();
      }
    }

    &__date {
      max-width: 500px;
      // min-width: 200px;
      @include flexRow;
      align-items: center;
      height: $input-height;
      border-radius: $default-border-radius;
      border-width: 1px;
      border-style: solid;
      border-color: $rigo-input-border-color;
      background-color: $rigo-input-bg-color;
      position: relative;
      transition: box-shadow 0.2s ease 0s, border-color 0.2s ease 0s;

      &:focus-within {
        @include inputFocusStyle;

        .form-group__date-icon {
          color: lighten($btn-primary-color, 15);
        }
      }
      &-customInput {
        border: none;
        font-size: $default-fontSize;
        background-color: transparent;
        flex: 1;
        height: 32px;
        padding: 2px 4px;
        &:focus {
          outline: none;
        }
      }
      &-input {
        border: none;
        font-size: $default-fontSize;
        background-color: transparent;
        flex: 1;
        height: 34px;
        width: 100%;
        padding: 8px;
        &:focus {
          @include inputFocusStyle;
        }
        &:disabled {
          background-color: #f1f1f1;
        }
      }
      &-icon {
        position: absolute;
        right: 0;
        cursor: pointer;
        width: 25px;
        font-size: 20px;
        color: $rigo-icon-color;
        transition: color ease 0.2;
      }
    }

    &__datepicker {
      z-index: 11111;
      position: absolute;
      top: 45px;
      width: 390px;
      // transform: translateX(-50%);
      left: 0;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
      // &:before{
      //   content:'';
      //   @include overlay(rgba(0,0,0,0.3));
      // }
    }

    &__custom-time {
      color: #222;
      @include placeholder {
        @include placeholderText();
      }
      @include hideInputNumberArrows;
      &-box {
        width: 25px;
        align-items: center;
        padding: 6px 4px;
        margin-right: 2px;
        // height: $input-height;
        border-radius: $default-border-radius;
        border-width: 1px;
        border-style: solid;
        border-color: $rigo-input-border-color;
        background-color: $rigo-input-bg-color;

        &:focus {
          @include inputFocusStyle;
        }
      }
      &-colon {
        font-size: larger;
        // margin: 0 8px;
        margin-right: 2px;
      }
      &-meridiem {
        align-items: center;
        // padding: 7px 0;
        margin-left: 5px;
        // height: $input-height;
        border-radius: $default-border-radius;
        background-color: $rigo-input-bg-color;
        transition: box-shadow 0.2s ease 0s, border-color 0.2s ease 0s;

        &-name {
          padding: 6px 4px;
        }

        .active {
          background-color: $color-blue;
          color: #fff;
        }

        button {
          border: unset;
          // height: $input-height;
          outline: none;
          cursor: pointer;
        }
      }
    }
    &__time {
      max-width: 300px;
      min-width: 175px;
      // width: 175px;
      @include flexRow;
      align-items: center;
      padding: 8px;
      height: $input-height;
      border-radius: $default-border-radius;
      border-width: 1px;
      border-style: solid;
      border-color: $rigo-input-border-color;
      background-color: $rigo-input-bg-color;
      transition: box-shadow 0.2s ease 0s, border-color 0.2s ease 0s;

      .react-time-picker {
        &__wrapper {
          border: unset;
          color: red;
        }

        &__clock {
          border: thin solid $rigo-input-border-color;
          top: 130% !important;
          left: -10px !important;
        }

        &__inputGroup {
          &__input {
            outline-color: grey;
            color: #444;
          }
        }

        &__button {
          outline: unset;
        }

        svg {
          stroke: grey;
        }
      }

      &:focus-within {
        @include inputFocusStyle;

        .form-group__time-icon {
          color: lighten($btn-primary-color, 15);
        }
      }
    }

    // &__select {
    //   width: 100%;
    //   // height: 2rem;
    //   // padding: .35rem;
    //   padding: 0.6rem 0.75rem;
    //   border-radius: 3px;
    //   background: "#f1f1f1";
    //   outline: none;
    //   border: 1px solid #d9d9d9;
    //   margin-top: 0.35rem;

    //   &:focus {
    //     @include inputFocusStyle;
    //   }
    // }
    &__search {
      display: flex;
      align-items: center;
      border: 1px solid $rigo-input-border-color;
      background: $rigo-input-bg-color;
      border-radius: $default-border-radius;
      height: $input-height;
      width: 100%;
      max-width: 400px;
      padding: 0 10px;
      &:focus-within {
        @include inputFocusStyle;
        .genericForm-group__search-icon {
          svg {
            color: #{$btn-primary-color};
          }
        }
      }
      &-input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        color: #444;
        font-size: 13px;
        @include placeholder {
          @include placeholderText();
        }
      }
      &-icon {
        width: $input-height;
        height: $input-height;
        display: flex;
        margin: 0 5px;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 18px;
          color: #7f7f7f;
        }
      }
    }
  }
  &-custom-date {
    width: 100%;
    border: none;
    .react-date-picker__wrapper {
      border: none;
      margin: 0 5px;

      svg {
        stroke: #8e9094;
      }
    }
  }
}

.ydm-input {
  &-group {
    // display: grid;
    // grid-template-columns: repeat(3,1fr);
    // max-width: 450px;
    // grid-gap: 10px;
    display: grid;
    grid-template-columns: 90px 1fr 100px;
    // max-width: 362px;
    width: 300px;
    padding: 1px;
    height: $input-height;

    border: 1px solid #b7b7b7;
    border-radius: $default-border-radius;
    &-1 {
      grid-template-columns: repeat(1, 1fr);
      max-width: unset;
      width: 362px;
    }
    &-2 {
      grid-template-columns: repeat(auto-fit, minmax(100px, 120px));
      max-width: unset;
      width: 242px;
    }
    .css-1gtu0rj-indicatorContainer,
    .css-tlfecz-indicatorContainer {
      padding: 9px 4px;

      svg {
        width: 17px;
        height: 17px;
      }
    }
    &:focus-within {
      @include inputFocusStyle;

      .ydm-input-col {
        border-width: 2px;

        border-color: rgba($btn-primary-color, 0.5);
      }
    }
  }
  &-col {
    width: 100%;
    height: 32px;
    &__label {
      @include label;
    }

    &:not(:last-child) {
      border-right: 1px solid #b7b7b7;
    }

    .css-yk16xz-control,
    .css-1pahdxg-control {
      border-radius: 0 !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      height: 30px !important;
      min-height: 30px !important;
    }
  }
}

.custom-date {
  input {
    &:focus {
      box-shadow: none;
    }
  }
  &.custom-date__active {
    .custom-date__overlay {
      display: block;
    }
  }
  &__overlay {
    display: none;
    @include overlay(rgba(0, 0, 0, 0.2));
    position: fixed;
    z-index: 1;
  }
}
.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  height: 100%;
  &:after,
  &:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &:before {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid rgba(#2c2d2c, 0.5);
    top: 35%;
  }
  &:after {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid rgba(#2c2d2c, 0.5);
    top: 65%;
  }
}

.uploaded-files {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5px 0 0px;
  margin-top: unset;
  &.up-col {
    flex-direction: row;
  }
}

.uploaded-file {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: unset;
  height: unset;
  background: transparent;
  padding: 7px 0;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  &-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    // min-width: 200px;
    // min-height: 175px;
    background-color: #f2f2f2;
    width: 150px;
    height: 140px;
    border-radius: 8px;
    margin-left: 5px;
  }

  &-pdf {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  &-name {
    flex-grow: 1;
    margin-right: 10px;
    font-size: 14px;
    color: #0066ff;
    text-decoration: underline;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.2s;
    border-radius: 8px;
  }
  .name {
    color: #fff;
    font-size: 11px;
    flex-grow: 1;
    width: 88%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .icon {
    background: #222;
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: #{$color-red};
    }
  }
  .actions {
    position: absolute;
    // visibility: hidden;
    width: 100%;
    // height: 100%;
    // height: 35px;
    top: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-image: linear-gradient(180deg, #0066ff, transparent);
    display: flex;
    // align-items: center;s
    // justify-content: space-between;
    padding: 5px;

    .modal__content {
      width: 80% !important;
    }
  }
  // &:hover {
  //   .actions {
  //     visibility: visible;
  //     background-color: #00000066;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     .icon {
  //       padding: 2px;
  //       margin: 0 5px;
  //       background-color: #ddd;
  //       border-radius: 5px;
  //       cursor: pointer;
  //       color: $color-blue;
  //     }
  //   }
  // }
}

.input-group-disabled {
  .css-1fhf3k1-control {
    background-color: #eee;
    border: 1px solid #b7b7b7;
    .css-107lb6w-singleValue {
      color: #444;
    }
  }
  .ydm-input-group {
    overflow: hidden;
    background: #eee;
  }
  .ydm-input-col {
    background-color: #eee;
    // border: 1px solid #b7b7b7;
    .css-1fhf3k1-control {
      background-color: #eee;
      border: none;
      .css-107lb6w-singleValue {
        color: #444;
      }
    }
  }
  .genericForm-custom-date {
    background-color: #eee;
    .react-date-picker__button {
      border: none;
      background: transparent !important;
    }
  }
  .genericForm-group__date {
    background-color: #eee;
    border: 1px solid #b7b7b7;
  }
}
