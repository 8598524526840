.btn {
  &,
  &:link,
  &:visited {
    // text-transform: capitalize;
    text-decoration: none;
    padding: 0.6rem 1.2rem;
    display: inline-block;
    border-radius: 4px;
    transition: all 0.2s;
    position: relative;
    font-size: 0.8rem;
    font-weight: 400;
    background-color: #d4d4d4;
    color: #646464;
    //Change for the <button> element
    border: none;
    margin: 0 0.5rem;
    cursor: pointer;
    height: 35px;
  }

  &:hover {
    // transform: translateY(-3px);
    // box-shadow: 0 0.1rem 0.25rem rgba(#222, 0.2);
    filter: brightness(0.9);
    &::after {
      // transform: scaleX(1.2) scaleY(1.3);
      // opacity: 0;
    }
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.07rem 0.1rem rgba(#222, 0.12);
  }
  &-disable {
    pointer-events: none;
    background-color: #d8d8d8 !important;
    color: #666 !important;
  }
  &-large {
    padding: 0.7rem 1.75rem !important;
  }
  &-submit {
    color: #{$color-primary} !important;
    background: #{$color-primary-light-3} !important;
    margin-top: 1rem;
    // border: 1px solid #646464;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.12);
  }
  &-grey {
    background-color: #e4e7ea;
    color: #636e7b;
    margin-top: 15px;
    height: 2rem;
    padding: 0.6rem 1.5rem;
    // margin-left: auto;
    font-weight: 600;
    letter-spacing: 0.03rem;

    margin-right: 10px;
  }

  &-primary {
    background-color: #{$color-primary} !important;
    color: #fff !important;
    padding: 10px 40px;

    &:hover {
      background: lighten($color-primary, 10);
      transform: unset;
    }
  }
  &-white {
    background-color: #fafbfc !important;
    color: #{$color-primary} !important;
    border: 1px solid #b9ccde;
    border-radius: 4px;
    svg,
    i {
      color: #b9ccde;
    }
    &:hover {
      background: darken(#fafbfc, 10);
      transform: unset;
      box-shadow: unset;
    }
  }
  &-blue {
    background-color: #{$color-blue} !important;
    color: #fff !important;

    &:hover {
      background: lighten($color-blue, 10);
      transform: unset;
    }
  }
  &-red {
    background-color: #{$color-red} !important;
    color: #fff !important;

    &:hover {
      background: lighten($color-red, 10);
      transform: unset;
    }
  }
  &-grey-2 {
    background-color: #e5e5e5 !important;
    color: #{$color-primary} !important;

    &:hover {
      background: lighten(#d1d1d1, 10);
      transform: unset;
    }
  }
  &-del {
    background-color: #c6c6c6;
    color: #616161 !important;

    &:hover {
      background: lighten(#d3d3d3, 10);
      transform: unset;
      filter: unset;
    }
  }
  &-green {
    background-color: #{$color-green};
    color: #fff;

    &:hover {
      background: lighten($color-green, 2);
      transform: unset;
    }
  }
  &.with-icon {
    display: flex;
    align-items: center;
    max-width: 10rem;

    svg,
    i {
      margin-right: 10px;
      font-size: 1rem;
    }
  }

  &-large {
    padding: 0.8rem 1.75rem;
  }
}

.btn-outline {
  &,
  &:link,
  &:visited {
    // text-transform: capitalize;
    text-decoration: none;
    padding: 0.6rem 1.2rem;
    display: inline-block;
    border-radius: 4px;
    transition: all 0.2s;
    position: relative;
    font-size: 0.8rem;
    font-weight: 400;
    margin: 0 0.5rem;
    cursor: pointer;
    background: transparent;
    border: 1px solid #d4d4d4;
    // color: #d4d4d4;
    color: #444;
  }
  &:hover {
    background: #efefef;
  }
  &:active {
    outline: none;
  }

  &__black {
    color: #333 !important;
    border: 1px solid #aaa !important;
    &:hover {
      background: #efefef !important;
    }
  }
  &__white {
    color: #f7f7f7 !important;
    border: 1px solid #f7f7f7 !important;
    &:hover {
      background: #333 !important;
    }
  }
  &__primary {
    color: #{$color-primary} !important;
    border: 1px solid #{$color-primary} !important;

    &:hover {
      background: #{$color-primary-light-4} !important;
    }
  }
  &__blue {
    color: #{$color-blue} !important;
    border: 1px solid #{$color-blue} !important;

    &:hover {
      background: #{$color-blue-fade};
    }
  }
  &__green {
    color: #{$color-green} !important;
    border: 1px solid #{$color-green} !important;

    &:hover {
      background: #{$color-green-fade};
    }
  }
  &__danger {
    color: #{$color-red} !important;
    border: 1px solid #{$color-red} !important;

    &:hover {
      background: #{$color-red-fade};
    }
  }
}
.btn-rounded {
  &,
  &:link,
  &:visited {
    // text-transform: capitalize;
    text-decoration: none;
    padding: 1rem 1.5rem;
    display: inline-block;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    color: #186faf;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    border-radius: 3rem;
    background-color: #f0f3f8;
    position: relative;
    outline: none;
    border: none;
    cursor: pointer;
  }

  span {
    vertical-align: middle;
  }
  i,
  svg {
    vertical-align: middle;
    margin-right: 0.5rem;
    font-size: 1.4rem;
  }
  &.left-icon {
    display: flex;
    align-items: center;
    padding: 0.4rem 0.8rem 0.4rem 0.4rem;
    svg,
    i {
      font-size: 24px;
      margin-right: 0.2rem;
    }
  }
  &.right-icon {
    display: flex;
    padding: 0.4rem 0.4rem 0.4rem 0.8rem;
    align-items: center;
    svg,
    i {
      font-size: 24px;
      margin-left: 0.2rem;
    }
  }
  &.primary {
    background-color: #{$color-primary};
    color: #{$color-primary-light-3};
    &:hover {
      background-color: lighten($color-primary, 8);
    }
  }
  &.primary-light {
    background-color: #4098d7;
    color: #fafafa;
    &:hover {
      background-color: lighten(#4098d7, 8);
    }
  }
  &.green {
    background-color: #{$color-green};
    color: #{$color-white};
    &:hover {
      background-color: lighten($color-green, 8);
    }
  }

  &.red {
    background-color: #{$color-red};
    color: #{$color-white};
    &:hover {
      background-color: lighten($color-red, 8);
    }
  }
  &.with-icon {
    display: flex;
    align-items: center;
    max-width: 10rem;

    svg,
    i {
      margin-right: 10px;
      font-size: 1rem;
    }
  }
  &.blue {
    background-color: #{$color-primary-dark};
    color: #fafafa;

    &:hover {
      background-color: lighten($color-primary-dark, 8);
    }
  }
  &.blue2 {
    background-color: #{$color-blue};
    color: #fafafa;

    &:hover {
      background-color: lighten($color-blue, 8);
    }
  }
  &.light-yellow {
    background-color: #fff6d1;
    color: #475763;
  }

  &__small {
    padding: 0.5rem 0.75rem !important;
    font-size: 1rem !important;
  }
  &__med {
    padding: 0.75rem 1rem !important;
    font-size: 1.2rem !important;
  }
}

.btn-text {
  &,
  &:link,
  &:visited {
    text-transform: capitalize;
    text-decoration: none;
    padding: 4px 2px;
    display: inline-block;
    /* border-radius: 4px; */
    transition: all 0.2s ease-in;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    color: #444;
    background: transparent;
    outline: none;
    border: none;
    // border: none;
    border-bottom: 1px solid #444;
    cursor: pointer;
    &.with-icon {
      display: flex;
      align-items: center;
      max-width: 10rem;

      svg,
      i {
        margin-left: 10px;
        font-size: 1rem;
      }
    }

    @include respond(med-desktop) {
      padding: 6px 3px;
      font-size: 16px;
    }
    &:hover {
      background: #444;
      color: #fff;
      border-bottom: 1px solid #fff;
      padding: 0.5rem 0.6rem;
    }
  }

  &__white {
    color: #fff !important;
    border-bottom: 1px solid #fff !important;

    &:hover {
      background: #fff !important;
      border-bottom: 2px solid #444 !important;
      color: #444 !important;
    }
  }
}

.button-group {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 10px;
  margin-top: 1rem;
  &__right {
    flex-direction: row-reverse;
  }
  &__center {
    justify-content: center;
  }
}

.upload {
  &-btn {
    color: white;
    background-color: #{$color-primary-light};
    padding: 10px 25px;
    border-radius: 12rem;
    font-size: 14px;
    font-weight: 500;
    border: none;
    &.with-icon {
      display: flex;
      align-items: center;
      max-width: 15rem;

      svg,
      i {
        margin-right: 10px;
        font-size: 1rem;
      }
    }
    &-wrapper {
      position: relative;
      overflow: hidden;
      // display: inline-block;
      flex-direction: column;

      input[type="file"] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        height: 100%;
      }
    }
  }
}

.btn-height-center {
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  padding: 1rem 1.25rem;
}

.grid-btn {
  width: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem 1.5rem !important;
}

.button-spin {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: 2px solid;
  border-color: #fafaf9;
  margin-right: 10px;
  border-bottom-color: transparent;
  display: inline-block;
  -webkit-animation: spin 0.75s 0s infinite linear;
  animation: spin 0.75s 0s infinite linear;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

button:disabled,
button[disabled] {
  border: 1px solid #bbbbbb;
  background-color: #e0e0e0 !important;
  color: #5a5a5a !important;
  // pointer-events: none !important;
  cursor: not-allowed !important;
}

.button {
  &,
  &:link,
  &:visited {
    // text-transform: capitalize;
    text-decoration: none;
    padding: 0px 16px;
    display: inline-block;
    border-radius: 3px;
    transition: all 0.2s;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    background-color: #d4d4d4;
    color: #646464;
    //Change for the <button> element
    border: none;
    margin: 0 4px;
    cursor: pointer;
    height: $button-height;
    border: 1px solid;
    width: max-content;
    font-family: $rigo-font-stack;
  }
  &-small {
    height: 32px;
    padding: 0 8px;
  }
  &-large {
    height: 40px;
    padding: 0 24px;
  }
  &-icon {
    line-height: 0;
  }
  &:focus {
    outline: none;
  }

  &-left-icon {
    svg {
      margin-left: 0 !important;
      margin-right: 7px;
    }
  }
  &-right-icon {
    svg {
      margin-right: 0 !important;
      margin-left: 7px;
    }
  }

  &:hover {
    background-color: #e4e4e4;
    &::after {
      // transform: scaleX(1.2) scaleY(1.3);
      // opacity: 0;
    }
  }
  &.with-icon {
    display: flex;
    align-items: center;
    max-width: 250px;

    svg,
    i {
      margin-right: 7px;
      font-size: 18px;
      color: #fff;
    }
  }
  &.with-drop {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    & > span {
      flex-grow: 1;
      padding: 0 16px;
    }
    .button-drop {
      width: 35px;
      height: $button-height;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #cdd3da;

      &:hover {
        // filter: brightness(.85);
      }

      svg {
        font-size: 18px;
      }
    }
  }
  &.with-just-drop {
    & > span {
      height: 36px;
      padding: 0 8px 0 16px;
      display: flex;
      align-items: center;
    }
    .button-drop {
      border-left: none;
      width: 30px;
      justify-content: flex-start;
      font-size: 14px;
    }
  }
  &-disabled {
    &,
    &:disabled {
      background-color: #{$btn-disabled-color} !important;
      pointer-events: none !important;
      cursor: not-allowed !important;
      color: #{$btn-disabled-text-color} !important;
      border: 1px solid #{$btn-disabled-border-color} !important;
      &:hover {
        background-color: #{$btn-disabled-color};
      }
    }
  }
  &-primary {
    @include buttonMixin($btn-primary-color, #fafafa, lighten);
    border-color: $btn-primary-color;
  }
  &-primary-lightest {
    @include buttonMixin($btn-primary-light-color, #fafafa, darken);
  }
  &-primary-dark {
    @include buttonMixin($btn-primary-dark-color, #fafafa, lighten);
  }
  &-danger {
    @include buttonMixin($btn-danger-color, #fafafa, lighten);
  }
  &-danger-light {
    @include buttonMixin($btn-danger-light-color, #fafafa, darken);
  }
  &-danger-dark {
    @include buttonMixin($btn-danger-dark-color, #fafafa, lighten);
  }
  &-green {
    @include buttonMixin($btn-green-color, #fafafa, lighten);
  }
  &-green-dark {
    @include buttonMixin($btn-green-dark-color, #fafafa, lighten);
  }
  &-success {
    @include buttonMixin(#18bc15, #fafafa, lighten);
    &:hover {
      background-color: #008400;
    }
    border: none;
  }
  &-cancel {
    @include buttonMixin(#6692b0, #fafafa, lighten);
  }
  &-secondary {
    @include buttonMixin($btn-secondary-color, #333, lighten);
    border-color: #b0b9c2;
  }
  &-secondary-light {
    @include buttonMixin($btn-secondary-light-color, #333, darken);
    border-color: #b0b9c2;
  }
  &-secondary-dark {
    @include buttonMixin($btn-secondary-dark-color, #333, lighten);
    border-color: #b0b9c2;
  }
  &-highlight {
    @include buttonMixin($btn-highlight-color, #0581fe, darken);
    border: 1px solid #dde5ed;
  }
  &-primary-light {
    @include buttonMixin(#ecf4ff, #0581fe, darken);
  }
  &-grey {
    @include buttonMixin(#efefef, #0581fe, darken);
  }
  &-warning {
    @include buttonMixin($rigo-warning-color, #fafafa, darken);
  }
  &-subtle {
    @include buttonMixin($rigo-subtle, #3a3d43, darken);
    border: 1px solid $rigo-subtle;
    &:hover {
      background-color: #e5e5e5;
    }
  }
  &-subtle-error {
    @include buttonMixin($rigo-subtle, #3a3d43, darken);
    border: 1px solid $rigo-subtle;
    &:hover {
      background-color: #e5e5e5;
      color: #{$rigo-danger-color};
      .button-text,
      svg {
        color: #{$rigo-danger-color} !important;
      }
    }
  }
  &-white {
    @include buttonMixin(#fcfcfd, #3a3d43, darken);
    border: 1px solid #dde5ed;
  }
  &-black {
    @include buttonMixin(#29333d, #fafafa, lighten);
  }
}

.button-outline {
  &,
  &:link,
  &:visited {
    // text-transform: capitalize;
    text-decoration: none;
    padding: 0px 16px;
    display: inline-block;
    border-radius: 3px;
    transition: all 0.24s;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    background-color: transparent;
    color: #646464;
    border: 1px solid #646464;
    //Change for the <button> element
    border: none;
    margin: 0 4px;
    cursor: pointer;
    height: $button-height;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #646464;
    color: #fafafa;
  }
  &.with-icon {
    display: flex;
    align-items: center;
    max-width: 180px;

    svg,
    i {
      margin-right: 7px;
      font-size: 18px;
      color: #fff;
    }
  }
  &-disabled {
    &,
    &:disabled {
      background-color: #{$btn-disabled-color} !important;
      // pointer-events: none !important;
      cursor: not-allowed !important;
      color: #{$btn-disabled-text-color} !important;
      border: 1px solid #{$btn-disabled-border-color} !important;
      &:hover {
        background-color: #{$btn-disabled-color};
      }
    }
  }
  &-primary {
    @include buttonOutlineMixin($btn-primary-color, #fafafa, lighten);
  }
  &-primary-light {
    @include buttonOutlineMixin($btn-primary-light-color, #fafafa, darken);
  }
  &-primary-dark {
    @include buttonOutlineMixin($btn-primary-dark-color, #fafafa, lighten);
  }
  &-danger {
    @include buttonOutlineMixin($btn-danger-color, #fafafa, lighten);
  }
  &-danger-light {
    @include buttonOutlineMixin($btn-danger-light-color, #fafafa, darken);
  }
  &-danger-dark {
    @include buttonOutlineMixin($btn-danger-dark-color, #fafafa, lighten);
  }
  &-green {
    @include buttonOutlineMixin($btn-green-color, #fafafa, lighten);
  }
  &-green-dark {
    @include buttonOutlineMixin($btn-green-dark-color, #fafafa, lighten);
  }
  &-success {
    @include buttonOutlineMixin(#00a400, #fafafa, lighten);
  }
  &-secondary {
    @include buttonOutlineMixin($btn-secondary-color, #333, lighten);
  }
  &-secondary-light {
    @include buttonOutlineMixin($btn-secondary-light-color, #333, darken);
  }
  &-secondary-dark {
    @include buttonOutlineMixin($btn-secondary-dark-color, #333, lighten);
  }
  &-highlight {
    @include buttonOutlineMixin($btn-highlight-color, #0581fe, darken);
  }
  &-black {
    @include buttonOutlineMixin(#29333d, #fafafa, lighten);
  }
}

.disabled-link {
  pointer-events: none;
}

.color-white {
  color: white !important;
}
.color-red {
  color: $rigo-danger-color;
}

.button-drop {
  position: relative;
  &__active {
    .button-drop__content {
      opacity: 1;
      visibility: visible;
      right: 0px;
      top: 40px;
      transform: translateY(0px);
      &::after {
        position: absolute;
        top: -8px;
        left: 85%;
      }
    }
  }
  &__icon {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__content {
    @include dropDownContent;
    right: 0px;
    top: 40px;
    width: 200px;
    transform: translateY(10px);
    transition-duration: 0.2s;

    &:after {
      display: none;
    }
  }
  &-list {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__item {
      height: 38px;
      width: 100%;
      padding: 10px;
      font-size: 14px;
      color: #666;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background-color: #fff;

      &:hover {
        background-color: #efefef;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #efefef;
      }
    }
  }
}

.not-allowed {
  position: relative;

  &:after {
    content: "You dont have enough permission to complete this action";
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    background-color: #{$btn-danger-color};
    color: #fff;
    padding: 6px;
    border-radius: 4px;
    width: 240px;
    display: none;
    font-size: 13px;
    z-index: 111111111;
  }
  &:hover {
    &:after {
      display: block;
    }
  }
}

.button-action {
  border: 1px solid #007aff;
  color: #007aff;
  svg {
    color: #007aff !important;
  }
}

.button-approve {
  background: #18bc15 !important;
  color: #fff !important;

  &:hover {
    background: lighten(#18bc15, 10) !important;
  }
}

.animated-delete {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  // transition: width .4s ease-in-out, border-radius .2s .2s  ;

  &-icon {
    background-color: #{$btn-danger-color};
    color: #fff;

    &:hover {
      background-color: darken($btn-danger-color, 6);
    }
  }
}

.light-blue {
  color: #fff;
  background: #6692b0;
  span {
    color: #fff !important;
  }
}
