.tabs {
  width: 100%;
  display: block;
  background: #{$rigo-white-color};
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  &-no-bg {
    background: transparent;
    box-shadow: unset;
    .tabs-list {
      background: #{$rigo-white-color};
    }
    .tabs-content {
      background: transparent;
      //padding: 10px 0 !important;
      padding: 5px 0 !important;
    }
  }

  &-footer {
    height: 55px;
    padding: 10px 30px;
    background: #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &-list {
    list-style: none;
    // background: transparent;
    // background: #f5f5f59e;
    background: #f5f5f8;
    // New
    display: flex;
    flex-direction: row;
    // New
    // border-bottom: 2px solid #{$color-blue};
    // border-bottom: 2px solid #e5e9ed;
    width: 100%;
    padding: 0;
    margin-bottom: unset;
    border: 1px solid #eee;

    &__item {
      // border-top-left-radius: 4px;
      // border-top-right-radius: 4px;
      // border-top: 1px solid #dcdcdc;
      // border-right: 1px solid #dcdcdc;
      // border-left: 1px solid #dcdcdc;

      font-size: 14px;
      color: #666;
      margin-right: 5px;
      display: inline-block;
      cursor: pointer;
      // background: #fcf9f9;
      // New
      // padding: 8px 16px;
      padding: 10px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 100px;
      font-weight: 400;
      // New

      .tab-count {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 22px;
        height: 22px;
        border-radius: 50px;
        background-color: #707070;
        color: #fff;
        font-size: small;
        margin-left: 0.25rem;
      }
      &.tab-active {
        // background: #{$color-blue};
        // color: #{$rigo-white-color};
        border-bottom: 2px solid #{$color-blue};
        color: #{$color-blue};
        font-weight: 500;

        .tab-count {
          background-color: #{$color-blue};
        }
      }
      &.tab-disabled {
        // pointer-events: none;
        cursor: default;
        position: relative;
        &:hover {
          &:after {
            display: block;
          }
          &:before {
            display: block;
          }
        }
        &:after {
          content: "" attr(data-tool) "";
          position: absolute;
          top: 42px;
          background: #898989;
          padding: 5px 8px;
          border-radius: 3px;
          left: 50%;
          transform: translateX(-50%);
          width: max-content;
          color: #fafafa;
          display: none;
          box-shadow: 0 2px 4px rgba(89, 89, 89, 0.13);
        }
        &:before {
          content: "";
          box-sizing: border-box;
          position: absolute;
          height: 12px;
          width: 12px;
          /* z-index: -1; */
          background-color: #898989;
          border: 1px solid #e5eaed;
          border-bottom: 1px solid transparent;
          border-right: 1px solid transparent;
          transform: rotate(45deg) translateX(-50%);
          top: 40px;
          left: 50%;
          display: none;
          box-shadow: 0 2px 4px rgba(89, 89, 89, 0.13);
        }
      }
    }
  }
  &-primary {
    .tabs-list {
      border-bottom: 2px solid #{$color-primary};
      &__item {
        &.tab-active {
          background: #{$color-primary};
          color: #{$rigo-white-color};
        }
      }
    }
  }
  &-success {
    .tabs-list {
      border-bottom: 2px solid #{$color-green};
      &__item {
        &.tab-active {
          background: #{$color-green};
          color: #{$rigo-white-color};
        }
      }
    }
  }
  &-warning {
    .tabs-list {
      border-bottom: 2px solid #{$color-yellow};
      &__item {
        &.tab-active {
          background: #{$color-yellow};
          color: #{$rigo-white-color};
        }
      }
    }
  }
  &-danger {
    .tabs-list {
      border-bottom: 2px solid #{$color-red};
      &__item {
        &.tab-active {
          background: #{$color-red};
          color: #{$rigo-white-color};
        }
      }
    }
  }

  &-block {
    &__list {
      border-bottom: 1px solid #dadada;
      padding-left: 0;
      width: 100%;
      //background-color: #f1f7fe;
      background: #f5f7fa 0% 0% no-repeat padding-box;
      transition: all 0.3s;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      display: flex;
      flex-direction: row;
      max-height: 40px;

      .tabs-list__item {
        max-height: 40px;
        font-size: 13px;
      }
    }
    &__item {
      display: inline-block;
      list-style: none;
      margin-bottom: -1px;
      // padding: 0.5rem 0.75rem;
      color: #777;
      padding: 15px;
      font-size: 0.9rem;
      cursor: pointer;
      max-height: 40px;

      @include respond(med-desktop) {
        font-size: 14px;
        padding: 10px 20px;
      }
      @include respond(big-desktop) {
        font-size: 15px;
        padding: 10px 20px;
      }
      &:hover {
        color: #0a7ed3;
      }
    }
    &.tabs-collapse {
      .tabs-content {
        min-height: 0;
        max-height: 0;
        padding: 0;
        height: 0;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease-out;
        padding: 0 !important;
        .filters-input {
          // display: none;
          opacity: 0;
          visibility: hidden;
          margin: 0;
        }
      }
    }
    &.tabs-expand {
      .tabs-content {
        max-height: 18rem;
        .filters-input {
          opacity: 1;
          visibility: visible;
          margin: 8px 0 10px;
          transition: opacity 0.000005s 0.3s linear;
        }
      }
    }
    .tabs-collapse-icon,
    .tabs-expand-icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: auto;
      // flex-grow: 1;

      .tabs-icon {
        transition: transform 0.2s ease-in-out;
        font-size: 25px;
        line-height: 0;
        transform: rotate(180deg);
      }
      .tabs-filter {
        font-size: 13px;
        color: #646464;
        margin-right: 5px;
        display: flex;
        align-items: center;
        &__icon {
          width: 15px;
          height: 15px;
          margin-right: 5px;

          img {
            width: 100%;
            height: 100%;
            filter: invert(0.5);
          }
        }
      }
    }
    .tabs-collapse-icon {
      .tabs-icon {
        transform: rotate(0deg);
      }
    }
    .tab-active {
      color: #006df9;
      font-weight: 500;
      border-bottom: 2px solid #006df9;
    }
  }
  &-content {
    padding: 10px 16px;
    transition: all 0.3s ease-in;
  }
}

.tab-list {
  border-bottom: 1px solid #dadada;
  padding-left: 0;
  width: 100%;
  background-color: #f1f7fe;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  color: #777;
  padding: 20px;
  cursor: pointer;

  &:hover {
    color: #0a7ed3;
  }
}

.tab-list-active {
  // background-color: white;
  color: #0a7ed3;
  font-weight: 500;
  border-bottom: 2px solid #0a7ed3;
}

.tab-content {
  padding: 20px;
}

.tabs-card {
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  background-color: #{$rigo-white-color};
  display: block;
  border-radius: 4px;
}

.sidetab {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.23);
  &-item {
    width: 100%;
    // margin-bottom: 0.75rem;
    text-decoration: none;
    transition: all 0.2s;
    &:not(:active) {
      .sidetab-contain:after {
        animation: ripple 1s ease-out;
      }
    }
    &:focus {
      .sidetab-contain:after {
        visibility: visible;
      }
    }
    // &:active {

    //     .sidetab-contain{
    //         background-color: #6eb9f7;
    //         background-size: 100%;
    //         transition: all 0s;
    //     }
    //   }

    a {
      text-decoration: none;
    }
    &.success {
      .sidetab-countdown {
        background: #{$color-green};
        &__number {
          background: #{$color-green-fade};
          color: #{$color-green};
        }
      }
      .sidetab-content {
        color: #{$color-green};
        background: #e6f6da;
      }
      &.selected {
        .sidetab-countdown {
          background-color: #408b00 !important;
          &__number {
            background-color: #50aa00 !important;
            color: #{$rigo-white-color} !important;
          }
        }
        .sidetab-content {
          color: #fafafa !important;
          background: #50aa00 !important;
        }
      }
    }
    &.primary {
      .sidetab-countdown {
        background: #{$color-blue} !important;
        &__number {
          background: #{$color-blue-fade} !important;
          color: #{$color-blue};
        }
      }
      // &:hover {
      //     background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
      // }
      .sidetab-content {
        color: #{$color-blue} !important;
        background: #eff4fa;
      }

      &.selected {
        .sidetab-countdown {
          background-color: #2b5997 !important;
          &__number {
            background-color: #407ed3 !important;
            color: #{$rigo-white-color} !important;
          }
        }
        .sidetab-content {
          color: #fafafa !important;
          background: #407ed3 !important;
        }
      }
    }
    &.disabled {
      .sidetab-countdown {
        background: #707070;
        &__number {
          background: #b7b7b7;
          color: #707070;
        }
      }
      .sidetab-content {
        color: #707070;
      }

      &.selected {
        .sidetab-countdown {
          background-color: #2c2d2c !important;
          &__number {
            background-color: #4e4e4e !important;
            color: #{$rigo-white-color} !important;
          }
        }
        .sidetab-content {
          color: #fafafa !important;
          background: #707070 !important;
        }
      }
    }
    &.sidetab-expand {
      transition: transform 0.3s ease-in-out;
      border: none;

      svg {
        transition: transform 0.3s ease-in-out;
      }
      .sidetab-contian {
        border: none;
      }
      .sidetab-countdown {
        background: #2b5997;
        &__number {
          background: #2b5997;
          color: #{$rigo-white-color};
        }
      }
      .sidetab-content {
        color: #{$rigo-white-color};
        background: #2b5997;
      }
      &.collapsed {
        svg,
        i {
          transition: transform 0.3s ease-in-out;
          transform: rotate(180deg);
        }
      }
    }
  }
  &-contain {
    width: 100%;
    display: flex;
    flex-direction: row;
    // border-radius: 2px;
    background-color: #ececec;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.23);
    position: relative;
    transition: all 0.2s;
    overflow: hidden;
    border-bottom: 1px solid #fafafa;
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      margin-left: 55px;
      background: #{$color-blue};
      opacity: 0.6;
      visibility: hidden;
      transform: scale(0);
      transform-origin: center;
    }
  }
  &-countdown {
    width: 50px;
    background-color: #b2b2b2;
    padding: 10px;
    @include flexCenter;
    @include respond(med-desktop) {
      width: 55px;
    }

    &__number {
      width: 30px;
      height: 30px;
      background-color: #efefef;
      border-radius: 50%;
      color: #555;
      font-size: 18px;

      @include flexCenter;

      @include respond(med-desktop) {
        width: 35px;
        height: 35px;
        font-size: 20px;
      }
    }
  }
  &-content {
    flex-grow: 1;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #c3c3c3;
    transition: all 0.2s 0.15s;

    h2 {
      font-size: 15px;
      color: inherit;
      font-weight: 400;
      flex-grow: 1;
      @include respond(med-desktop) {
        font-size: 17px;
      }
    }
  }

  &-expand {
    &__btn {
      @include flexCenter;
      transition: all 0.3s ease-in-out;
      padding: 10px;
      font-size: 16px;
      color: #646464;
      width: 35px;
      background: #c3dbee;
      border-radius: 4px;
      margin: 10px 0;

      @include respond(med-desktop) {
        width: 35px;
        font-size: 18px;
      }

      &.collapsed {
        svg,
        i {
          transition: transform 0.3s ease-in-out;
          transform: rotate(180deg);
        }
      }

      svg,
      i {
        transition: transform 0.3s ease-in-out;
        transform: rotate(0deg);
      }
    }
  }
}

@keyframes ripple {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.common-header {
  &-title {
    padding-bottom: 1rem;
    .route-name {
      font-weight: 500;
      font-size: 14px;
      color: #454545;
    }
  }
  &-tab {
    background: #{$rigo-white-color};
    //border-bottom: 2.5px solid #e4e4e4;
    .toptab {
      display: flex;
      align-items: center;
      &-item {
        padding: 0.8rem 1rem;
        height: 40px;
        border-bottom: 2.5px solid #{$rigo-white-color};
        // border-bottom: 2.5px solid #e4e4e4;
        &:hover {
          border-bottom: 2.5px solid #609fec;
        }
      }
      &-title {
        font-size: 14px;
        font-weight: 400;
        color: #5a5a5a;
      }
      .selected {
        border-bottom: 2.5px solid #{$rigo-primary-color};
        font-weight: 500;
        .toptab-title {
          font-weight: 500;
        }
        .list-count {
          background-color: #{$rigo-primary-color};
          color: #{$rigo-white-color};
        }
        .toptab-title {
          color: #{$rigo-primary-color};
        }
      }
      .unallocated-space {
        height: 40px;
        border-bottom: 2.5px solid #{$rigo-white-color};
        flex: 1;
      }
      .list-count {
        width: 27px;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #e4e4e4;
        border-radius: 50%;
        margin-left: 0.5rem;
        font-weight: 500;
        font-size: 13px;
        color: #6c6969;
      }
    }
  }
}
