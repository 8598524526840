.header {
    position: relative;
    width: 100%;
    // height: 4rem;
    flex: 0 0 auto;
    height: 55px;
    &-contain{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 3rem;
        height: 55px;
            // line-height: 60px;
        background-color: #{$rigo-white-color};
        // background-color: #0a7ed3;
        
        // -webkit-box-shadow: 0 4px 8px;
        // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        transition: all .5s cubic-bezier(.4,.02,.56,.92);
        
    }
}