.sidebar {
    flex: 0 0 65px;
    max-width: 65px;
    min-width: 65px;
    width: 65px;
    // -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 3px 10px 30px 3px rgba(0, 0, 0, 0.19);
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 3px 10px 30px 3px rgba(0, 0, 0, 0.19);
    transition: all 0.3s;
    position: relative;
    // background:#005897;
    background: #0068d4;
    padding: 0.6rem 0rem;
    z-index: 1111;
    &-sidetab {
      &.open {
        display: block;
      }
    }
    &-logo {
      text-align: center;
      padding: 0rem;
      // padding: 0rem 1.25rem;
      &-image {
          width: 42px;
          height: 42px;
          border-radius: 50%;
          background: #85c5f4;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          line-height: 0;
        img {
          width: 23px;
          height: 23px;
        }
      }
      // img{
      //     width: 100%;
      // }
      &__heading {
        display: none;
        font-size: 1.4rem;
        color: #{$color-primary-light-3};
        margin-top: 0.6rem;
      }
    }
  
    &-link {
      list-style: none;
      margin-top: 40px;
      &__icon {
        display: block !important;
        width: 100%;
        text-align: center;
        padding: 10px;
        background-color: transparent;
      }
      .active-link{
          .sidebar-link__item{
              .sidebar-link__icon {
                  background-color:  #0052ab;
                }
          }
      }
      .not-link{
          .sidebar-link__item{
              .sidebar-link__icon {
                  background-color:  transparent;
                }
          }
      }
      &__item {
        // display: block;
        // padding: .6rem 1.2rem;
        // // height: 4rem;
        // font-size: 1.05rem;
        // color: #{$color-primary-light-2};
        // text-align: center;
        // cursor: pointer;
        // position: relative;
  
        display: flex;
        flex-direction: column;
        align-items: center;
        // padding: .6rem 1.2rem;
        padding: 7px 10px;
        // height: 4rem;
        // font-size: 1.05rem;
        font-size: 15px;
        color: #{$color-primary-light-2};
        text-align: center;
        cursor: pointer;
        position: relative;
  
        &:hover {
          .sidebar-link__icon {
            background-color: #0077da;
            // .active{
            //     #0052ab
            // }
          }
          &:after {
            width: max-content;
            opacity: 1;
            padding: 7px;
            margin-left: 5px;
            height: 32px;
            color: #f1f1f1;
            visibility: visible;
          }
        }
        &:after {
          content: "" attr(name) "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 100%;
          padding: 0;
          width: 0;
          font-size: 14px;
          font-weight: 300;
          height: 0;
          opacity: 0;
          margin-left: 0;
          z-index: 11;
          background-color: rgba(#333, 0.9);
          color: transparent;
  
          border-radius: 4px;
          visibility: hidden;
          // transition: all 0.2s 0.05s;
          // display: none;
        }
  
        // position: relative;
  
        // span{
        //     display: none;
        //     // vertical-align: text-bottom;
        //     margin-top: 5px;
  
        // }
        i,
        svg {
          display: inline-block;
          font-size: 25px;
          // font-size: 1.4rem;
        }
  
        &:hover {
          color: #{$color-primary-light-3};
  
          // span{
          //     display: block;
          //     position: absolute;
          //     left:115%;
          //     background-color: rgba(#333,0.5);
          //     top: 10%;
          //     padding: 0.5rem 1rem;
          //     border-radius: 0.25rem;
  
          //     &:before{
          //         content:'';
          //         position: absolute;
          //         left: -21%;
          //         top:5%;
          //         width: 0;
          //         height: 0;
          //         border-top: 1.25rem solid transparent;
          //         border-bottom: 1.25rem solid transparent;
          //         border-right: 1.25rem solid rgba(#333,0.5);
          //     }
          // }
        }
      }
      .active .sidebar-link__item,
      .active.sidebar-link__item {
        background: #{$color-primary};
        color: #{$color-primary-light-3};
        margin: 0.25rem;
        border-radius: 8px;
      }
    }
  }