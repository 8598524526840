.navbar {
  &-first {
    .viewport {
      max-width: 1360px;
      margin: 0 auto;
    }

    &-left {
      display: flex;
      flex-direction: row;

      align-items: center;
      img {
        // width: 100px;
        height: 64px;
        // object-fit: cover;
      }
      &-company {
        color: #444;
        margin-left: 10px;
        line-height: 25px;
        &-name {
          font-size: 1.4rem;
          font-weight: 600;
        }
        &-address {
        }
      }
    }
    &-right {
      display: flex;
      flex-direction: row;
      .personal-detail {
        display: flex;
        flex-direction: column;
        margin-right: 2rem;
        .username {
          color: #224070;
          font-weight: 500;
        }
        .userid {
          margin-left: 1rem;
          color: #224070;
          font-weight: 500;
        }
        .email {
          color: #254372;
          font-size: $default-fontSize;
        }
      }
    }
  }
  &-second {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 1.5rem;
    // padding: 5px 10px 5px 15px;
    background-color: #1b5693;
    color: #fff;

    .viewport {
      @include containerWidth;
    }
    .organization {
      font-size: larger;
      font-weight: 500;
      cursor: pointer;
      a {
        color: #fff;
      }
    }
    .notice {
      font-size: medium;
      font-weight: 200;
    }
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 20px;
  height: 90px;
  // position: absolute;
  bottom: 0;
  background: #f1f5f8;

  &-link {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    &:hover {
      .footer {
        &-image {
          img {
            transform: scale(1.15);
          }
        }
        &-title {
          h1 {
            color: #{$color-blue};
          }
        }
      }
    }
  }

  &-image {
    width: 35px;
    height: 35px;
    background-color: #{$rigo-white-color};
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      backface-visibility: hidden;
      transform: translateZ(0);
      transition: all 0.25s;
    }
  }

  &-title {
    margin-left: 10px;
    h1 {
      font-size: 18px;
      font-weight: 500;
      color: #0167b1;
      transition: all 0.25s;
      text-transform: capitalize;
      font-family: "Roboto", sans-serif;
    }
  }
}
