// @import "../../../scss/abstracts/mixins";
// @import "../../../scss/abstracts/variables";

.recruit {
  .travel {
    padding: 10px 0px;
  }
  .btnTravelRequest {
    margin-left: 0px;
  }
  &-header {
    // padding:5px;
    display: flex;
    flex-direction: row;
    flex: 0 0 100%;
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    transition: all 0.3s;
    position: relative;
    margin-top: 15px;

    &-item {
      // justify-content: space-between;
      // margin: 10px;
      // align-items: center;
      display: flex;
      flex-direction: column;
      padding: 15px;
      background-color: #f1f1f1;
      margin-right: 15px;
      border-radius: 6px;
      width: 210px;
      cursor: pointer;

      &.disabled {
        pointer-events: none;
        cursor: not-allowed;
      }

      &.selected {
        background-color: #1391e6;
        .recruit-header-title {
          color: #fff;
        }
        .recruit-header-body {
          color: #b2d7f1;
        }
      }
    }
    &-icon {
      .icon-container {
        width: 35px;
        height: 35px;
        border-radius: 100%;
        border: 1px solid #444;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
      }
    }
    &-title {
      font-size: 16px;
      color: #38424d;
      font-weight: 500;
      text-transform: capitalize;
      text-align: left;
      // margin-left: 20px;
    }
    &-body {
      font-size: 13px;
      color: #747e86;
      line-height: 1.4;
      margin-top: 5px;
    }
  }
  .travel-month {
    height: 39px;
    svg {
      color: #0a7ed3;
    }
  }
}

.jobCreate {
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  flex: auto;
  background: #f0f2f5;
  @include respond(med-desktop) {
    width: 100%;
  }
  @include respond(big-desktop) {
    width: 90%;
  }
  &__breadCrumb {
    padding: 20px;
  }

  &__head {
    padding: 20px;
    width: calc(100% - 40px);
    margin: 10px;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    // @include respond(med-desktop){
    //     width:90%;
    // }
    // @include respond(big-desktop){
    //     width:80%;
    // }

    &-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &-buttons {
      display: flex;
      flex-direction: row;
      button {
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
  &__body {
    overflow-x: hidden;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex: auto;
    flex: auto;
    background: #f0f2f5;
    min-height: 0;
    .inline-form-group {
      .pickers {
        margin: 0;
        width: 300px;
        .MuiFormControl-root {
          width: 100%;
        }
      }
    }
    .experience {
      .form-group__label {
        display: none;
      }
      input[name="Experience"] {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.margin {
  &-topTen {
    margin-top: 10px;
  }
}

.center {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.panel-section {
  padding: 20px;
  width: calc(100% - 40px);
  margin: 10px;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}

.light-para {
  font-size: 14px;
  color: #787878;
  margin: 0;
  margin-left: 10px;
}

.form {
  &-block {
    width: 90%;
    margin: 1rem;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);

    .badges {
      cursor: pointer;
    }

    .inline-form-group {
      .genericForm-group__message {
        margin-left: 1rem;
        margin-top: unset !important;
      }

      .genericForm-group__label {
        margin-bottom: unset;
      }

      .badges {
        margin-right: 15px;
      }
      .genericForm-group__label > label {
        display: block;
        width: 175px;
        margin-right: 50px;
        text-align: right;
        color: rgba(0, 0, 0, 0.7);
        font-size: 14px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 500;
        white-space: nowrap;
      }
      label {
        margin-right: 25px !important;
      }
      .error__message {
        margin: 0;
      }
      .css-2b097c-container {
        width: 100%;
      }
    }
    @include respond(med-desktop) {
      width: 85%;
    }
    @include respond(big-desktop) {
      width: 80%;
    }

    .ck-content {
      height: 200px;
    }
    &__title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 15px;
      color: #444;
      font-weight: 500;
      margin: 0 0 15px;
      h3,
      h4,
      h5 {
        font-weight: 500;
      }
    }
    &__form {
      width: 100%;
      display: flex;
      flex-direction: column;
      & > div {
        margin-bottom: 15px;
      }

      .small-inputs {
        input {
          width: 100px !important;
          padding: 8px 10px !important;
          margin-bottom: 10px;
        }
        .to {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.65);
          margin: 0 15px;
        }
      }
    }
  }
}

.application {
  &-container {
    padding: 10px 20px;
    width: 80%;
    margin: 1rem auto;

    & > h3 {
      margin-bottom: 20px;
    }
  }
  &-form {
    width: 60%;

    &__list {
      list-style: none;
      display: flex;
      flex-direction: column;
    }
    &__item {
      padding: 15px;
      background-color: #fff;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      border-radius: 2px;
      transition: all 0.3s ease-in;
      &:hover {
        background-color: #f9f9f9;
      }
    }
    &__title {
      font-size: 14px;
      color: #454545;
      font-weight: 500;
      h3 {
        font-weight: 500;
      }
    }
    &__options {
      display: flex;
    }
    &__option {
      font-size: 14px;
      color: #676767;
      padding: 8px 12px;
      border-radius: 12rem;
      margin-right: 5px;
      font-weight: 500;
      cursor: pointer;

      &.active {
        background-color: #d9f1d9;
        color: #2a9ce4;
        transition: all 0.3s ease-in;

        &:hover {
          background-color: darken(#d9f1d9, 6);
        }
      }
    }
  }
}

.hiring {
  &-container {
    padding: 10px 20px;
    width: 80%;
    margin: 1rem auto;

    & > h3 {
      margin-bottom: 20px;
    }
  }
  &-stages {
    margin: 10px 0;
    width: 60%;

    &__list {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    &__item {
      margin-bottom: 15px;
      width: 100%;

      .add-btn {
        margin: 0;
        margin-top: 10px;
        background: #d9d9d9;
        max-width: 20rem;
      }
    }
    &-modal {
      .modal__content {
        width: 25% !important;
        background: #fff !important;
        min-height: 13rem !important;

        .modal__header {
          border-bottom: 1px solid #e0e0e0 !important;
        }
      }
    }
    &__form {
      display: flex;
      flex-direction: column;
      &-body {
        padding: 10px 15px;
        width: 80%;
      }
      &-input {
        input {
          padding: 8px 12px;
        }
        label {
          color: rgba(0, 0, 0, 0.9);
          padding: 0;
          font-size: 14px;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif;
          font-weight: 400;
          line-height: 2;
          letter-spacing: 0.00938em;
        }
        margin-bottom: 20px;
        .makeStyles-root-1 {
          margin-right: unset;
        }
        .MuiInputBase-root {
          &::before {
            content: none;
          }
        }
      }
      &-footer {
        padding: 10px 15px;
        display: flex;
        flex-direction: row-reverse;
        border-top: 1px solid #e0e0e0;
        button {
          margin-right: 10px;
          &.btn-grey-2 {
            color: #666 !important;
          }
        }
      }
    }

    &__btn {
      margin-top: 15px;
      width: 100%;
      button {
        &,
        &:focus,
        &:active {
          width: 100%;
          padding: 1rem;
          background-color: #bcccdb;
          color: #3a536d;
          text-transform: capitalize;
          outline: none;
          border: none;
          text-align: center;
          border-radius: 6px;
          font-size: 16px;
          cursor: pointer;
          font-weight: 500;
        }
        &:hover {
          background-color: lighten(#bcccdb, 5);
        }
      }
    }
  }
  &-stage {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__header {
      padding: 15px;
      background-color: #e1e6e9;
    }
    &__body {
      width: 100%;
      background-color: #fff;
      // padding: 10px;
    }
    &__subList {
      display: flex;
      flex-direction: column;

      .subItem {
        &__body {
          margin-left: 15px;
        }
        &__title {
          font-size: 14px;
          color: #252525;
          h4 {
            font-weight: 400;
          }
        }
        &__change {
          .btn-edit {
            margin-right: 15px;
          }
        }
      }
    }
    &__subItem {
      width: 100%;
      padding: 10px 15px;
      &:not(:last-child) {
        border-bottom: 2px solid #e4e2e2;
      }
    }
    &__name {
      align-items: flex-start;
      h3 {
        color: #333;
        font-size: 15px;
        margin-right: 15px;
        font-weight: 500;
      }
      p {
        color: #8c8c8c;
        font-size: 14px;
        font-weight: 400;
      }
    }
    &__change {
      .change-btn {
        &:first-child {
          margin-right: 15px;
        }
      }
    }
  }
}

.change-btn {
  width: 20px;
  height: 20px;
  font-size: 20px;
  color: #8c8c8c;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.2s;

  &.btn-edit {
    color: #e1e6e9;
    background-color: #8c8c8c;
    padding: 2px;
    font-size: 16px;
    border-radius: 2px;
    &:hover {
      background-color: #{$color-primary-light};
    }
  }
  &.btn-delete {
    &:hover {
      color: #{$color-red};
    }
  }
}

ul,
li {
  list-style: none;
}

.scorecards {
  padding: 10px 20px;
  width: 100%;
  & > h3 {
    margin-bottom: 20px;
  }
  &-container {
    margin: 10px 0;
    width: calc(100% - 20px);
    margin: 1rem auto;
  }
}

.hiringTeam {
  padding: 10px 30px;
  width: 100%;
  & > h3 {
    margin-bottom: 20px;
  }
  &-container {
    margin: 10px 0;
    width: calc(90% - 20px);
    margin: 1rem 0;
    &__title {
      color: #444;
      font-size: 15px;
      margin: 20px 0;

      h4 {
        font-weight: 500;
      }
    }
    .emp-image {
      width: 40px;
    }
  }
  &-button {
    button {
      border-color: #333;
      color: #333;
      background-color: #fff;
      font-weight: 500 !important;
      font-size: 14px;
      margin: 10px 0;
      &:hover {
        background-color: #f7f7f7;
      }
    }
  }
  &-form {
    width: 80%;
    display: flex;
    flex-direction: column;
    padding: 10px;

    .auto-error__message {
      line-height: 2.2;
    }

    &__input {
      padding: 10px;
    }

    &__radioLabel {
      display: flex;
      flex-direction: column;
      h4 {
        font-size: 14px;
        color: #333;
        font-weight: 500;
      }
      p {
        font-size: 12px;
        font-weight: 400;
        color: rgba(#333, 0.9);
      }
    }
  }
}

.modal-footer {
  padding: 10px 15px;
  display: flex;
  flex-direction: row-reverse;
  border-top: 1px solid #e0e0e0;
  button {
    margin-right: 10px;
    &.btn-grey-2 {
      color: #666 !important;
    }
  }
}

.label {
  color: rgba(0, 0, 0, 0.9);
  padding: 0;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  letter-spacing: 0.00938em;
}

.jobApply {
  min-height: 100vh;

  .common-route-title {
    margin: 0px 10px;
  }

  &__head {
    margin-top: 25px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.06);
    border-radius: 5px;

    &-top {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
    &-title {
      font-size: medium;
    }
    &-subTitle {
      margin-left: 10px;
      color: $color-grey;
      font-size: small;
    }
    &-dateSection {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 6px;
    }
    &-date {
      color: $color-grey;
      font-size: medium;
    }
    &-buttons {
      display: flex;
      flex-direction: row;
      button {
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
  &__body {
    overflow-x: hidden;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex: auto;
    flex: auto;
    background: #f0f2f5;
    min-height: 0;
  }

  &__pickers {
    //width: calc(100% - 225px);
    width: 300px;
    .MuiFormControl-root {
      width: 100%;
      .MuiInputBase-root {
        border: 1px solid #cacaca;
        border-radius: 4px;
        padding-left: 5px;
        color: #444;

        &::before {
          content: unset;
        }
        .area-body__item span {
          color: #888 !important;
        }
      }
    }

    //react-date-picker
    .react {
      &-date-picker {
        display: block;
        &__calendar {
          width: 300px;
        }
        &__wrapper {
          border: 1px solid #c5c5c5;
          height: 2.1rem;
          border-radius: 4px;
        }
        &__inputGroup {
          padding: 0 5px;
          min-width: unset;

          &__input {
            color: #444;
            outline: none;

            @supports (-moz-appearance: none) {
              padding: 5px 0;
              box-sizing: border-box;
            }
          }
          &__leadingZero {
            @supports (-moz-appearance: none) {
              display: inline-block;
              min-width: 17px;
            }
          }

          &__divider {
            padding: 3px;
          }
        }
        &__button {
          outline: none;
          svg {
            width: 14px;
            height: 14px;
            stroke: #666;
            stroke-width: 2;
            background: unset;
          }
        }
      }

      &-calendar {
        &__navigation {
          height: 35px;
          margin-bottom: 0.2em;
          background: #2196f3;

          button {
            color: white;
            font-size: medium;
          }
        }

        &__tile {
          color: #444;
          font-size: small;
          &--active {
            background: #2196f3 !important;
          }
        }

        &__month {
          &-view {
            &__weekdays {
              color: #444;
            }
          }
        }
      }
    }
  }
  .form-group {
    .pickers {
      width: 140.5px;
      margin: 0;
      // .MuiInputBase-input{
      //   &::placeholder {
      //     color: rgba(0, 0, 0, 0.9) !important;
      //     padding: 0;
      //     // transform: translate(0, 27px) scale(1);
      //     font-size: 2.85rem;
      //     font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      //     font-weight: 400;
      //     /* line-height: 1; */
      //     letter-spacing: 0.00938em;
      //   }
      // }
      .MuiInputBase-root {
        border: 1px solid #cacaca;
      }
    }
    .pickers:last-child {
      margin-left: 1rem;
    }
  }
  .error {
    .jobApply__pickers {
      .MuiInputBase-root {
        border: 1px solid #{$color-red};
        .MuiInputBase-input {
          border: none !important;
        }
      }
    }
  }
  .corresponding {
    .inline-form-group {
      label {
        width: 140px;
      }
    }
  }
  .form-block {
    margin: unset;
    margin-top: 1rem;
    width: 100%;
    border-radius: 5px;
    .error__message {
      margin: 10px;
    }
    .css-2b097c-container {
      width: 300px;
    }
  }
}
.form {
  &-partition {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .inline-form-group {
      width: 100%;
      max-width: 100%;
    }

    .age {
      margin-left: 200px;
      margin-top: -10px;
      color: #777;
    }
  }

  &-two_third_partition {
    display: grid;
    grid-template-columns: 66% 34%;
  }
  &-add__document {
    max-width: 120px;
  }
}

.chips {
  justify-content: center;
  .badges {
    margin-right: 30px;
  }
}

.address {
  &-subtitle {
    display: flex;
    // justify-content: center;
    // align-items: center;
    &-next {
      margin-left: 10px;
      color: #2196f3;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: #345453;
      }
    }
  }
}

.identification {
  .badges {
    margin-right: 30px;
  }
  &__document {
    margin-left: 150px;
  }
  .form-group {
    width: 100%;
    max-width: 100%;

    .badges.left-icon {
      padding: 0.25rem 1.5rem 0.25rem 0.25rem;
    }
    .badges {
      padding: 0.25rem 1.25rem;
    }
    .inline-form-group {
      max-width: unset !important ;
      width: unset !important;
    }
  }
}

.title__add {
  display: flex;
  flex-direction: row;
  align-items: center;
  // margin-bottom: 30px;
  &-button__position {
    margin-left: 30px;
  }
}

.detail {
  &-block {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    background: #f3f4f6;
    width: 97%;
    margin-right: 5px;
  }
  &-buttons {
    display: flex;
    max-width: 50%;
    justify-content: flex-end;
    align-items: center;
  }
  &-left {
    display: flex;
    flex-direction: column;
    flex: 6;
  }
  &-mid {
    display: flex;
    flex-direction: column;
    flex: 6;
  }
  &-right {
    display: flex;
    justify-content: space-between;
    // flex: 1;
    width: 60px;
  }
  &-dark__text {
    font-size: large;
  }
  &-light__text {
    color: $color-grey;
    font-size: small;
  }
}

.required_type {
  font-weight: 400;
  border-radius: 25px;
  padding: 3px 15px;
  font-size: 14px;
  line-height: 1;
}
.final__submit {
  margin-top: 25px;
}
.attach__file {
  justify-content: flex-start;
  align-items: flex-start !important;
}
.document {
  display: flex;
  margin: 5px 0 0 -5px;
  &-badge {
    color: $color-black;
  }
  &-name {
    margin-right: 1rem;
  }
  &-icon {
    font-size: 20px;
    color: $color-blue;
  }
}

.addDocument {
  &-name {
    margin-right: 1rem;
  }
  &-icon {
    font-size: 16px;
    margin-top: 4px;
    color: $color-blue;
  }
}
.cancel-icon {
  color: #{$color-red};
  margin-top: 3px;
}
.final__submit {
  justify-content: flex-end;
}

.pro-drop-avatar {
  height: 30px;
  width: 30px;
}

.user-avatar {
  display: block !important;
  margin: 0 auto 10px;
  object-fit: cover;
  max-width: 100%;
}

.upload {
  &-section {
    width: 390;
    height: 295;
    text-align: center;
    border: 2px dashed #979797;
    border-radius: "8px";
    background: #fbfbfb;
    margin: 0 auto;
  }

  .ReactCrop {
    max-height: 100%;

    .recruit-picker {
      margin-left: unset !important;
      width: calc(100% - 225px);

      & > div {
        max-width: 100%;
        max-height: 100%;

        img {
          object-fit: contain;
          max-height: 100%;
        }
      }
    }
  }
  &-section__new {
    width: 390;
    height: 295;
    text-align: center;
    margin: 0 auto;
  }
  &-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    span {
      font-weight: 500;
      font-size: 16px;
      color: #999;

      &:not(:first-child) {
        margin-top: 8px;
      }

      &.text-blue {
        color: #{$color-blue};
      }
    }
  }
  &-img {
    position: relative;
    width: 150px;
    height: 150px;
    border: 1px solid #dfdfdf;
    border-radius: 100%;
  }
  &-img__new {
    position: relative;
    width: 200px;
    height: 200px;
    // border: 1px solid #dfdfdf;
    border-radius: 100%;
  }
  &-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 30px !important;
    color: #8b959e !important;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      color: #{$color-red} !important;
    }
  }
  &-icon__new {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 25px !important;
    color: #8b959e !important;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      color: #{$color-red} !important;
    }
  }
}

.preview {
  &-section {
    margin-left: 20px;
  }
  &-img {
    width: 120px;
    margin-right: 25px;
    height: 120px;
  }
}

.crop {
  &-whole__section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &-done {
    margin-top: 15px;
  }
}

.addDocument {
  background-color: #fdc661 !important;
  color: $color-black !important;
  // margin-left: 20px;
  input {
    width: 200px;
    height: 2.5rem;
  }
}

.education {
  &-grade {
    width: 300px;

    &__chips {
      justify-content: flex-end;
      .badges {
        margin-right: 10px;
      }
    }

    // &__input {
    //   width: 140px;
    // }
  }
}
//   .MuiFormControl-root {
//     width: 100%;
//   }
// }

.create-new-job-modal {
  .modal__content {
    width: 600px !important;
    background: #f6fafd !important;

    .modal__header {
      background-color: #fff !important;
      border-bottom: none !important;

      .modal__heading {
        h2 {
          font-size: 18px !important;
          color: #{$color-primary-dark-2} !important;
        }
      }
    }
  }
}

.newJob {
  padding: 20px;

  &__listing {
    list-style: none;
    display: flex;
    flex-direction: column;
    &-item {
      background-color: #f4f4f4;
      padding: 12px 10px;
      margin-bottom: 15px;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        background-color: #e9e9e9;
      }
    }
    &-div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &-icon {
      font-size: 25px;
      margin-right: 15px;
      color: #b3b3b3;
    }
    &-details {
      display: flex;
      flex-direction: column;

      h4 {
        color: #444;
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 4px;
      }
      p {
        color: #a3a3a3;
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
}

.jobtitle {
  padding: 20px;
  &-listing {
    display: flex;
    flex-direction: column;
    &__item {
      padding: 10px 20px;
      background-color: #fff;
      border-bottom: 1px solid #efefef;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        background-color: #f7f7f7;
      }
      &:nth-child(even) {
        background-color: #fafafa;
        &:hover {
          background-color: #f1f1f1;
        }
      }
    }
    &__container {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      h4 {
        font-size: 14px;
        color: #333;
        font-weight: 400;
        margin-right: 10px;
      }
      p {
        font-size: 12px;
        color: #b7b7b7;
        font-weight: 400;
      }
    }
  }
}
