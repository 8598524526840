.layout{
    min-height: 100vh;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    flex: auto;
    background: #E9EFF8;
    position: relative;

    &-body{
        // overflow-x: hidden;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex: auto;
        flex: auto;
        background: #E9EFF8;
        min-height: 0;
        max-width: 100%;
        padding-bottom: 100px;
    }
    &-sidebar{

    }
    &-admin{
        .layout-body{
            padding-bottom: 20px;
            max-width: calc(100% - 70px);
        }
    }
    &-common{
        background-color: #f1f5f8;
        .app-container{
            background-color: #f1f5f8;
            justify-content: center;
            align-items: center;
        }
    }
}