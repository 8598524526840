// @import "../../../scss/abstracts/mixins";

$font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol";

$background: #fafafa;

@mixin btn-apply {
  margin: 0.5rem 0 0;
  padding: 1rem 5rem;
  background-color: #82b53f;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .left-arrow {
    margin-left: 10px;
    font-size: 1.2rem;
  }
}

@mixin deadline {
  display: flex;
  align-items: center;
  justify-content: center;
  &__header {
    color: #ff5f36;
    font-weight: 500;
  }
  &__value {
    color: #ff4515;
    font-weight: 500;
  }
}

@mixin right-col {
  @include respond(desktop) {
    width: 25vw;
  }
  @include respond(med-desktop) {
    width: 25vw;
  }
  @include respond(big-desktop) {
    width: 20vw;
  }
}

.block {
  -webkit-box-shadow: 0px 0px 5px 1px #eee;
  -moz-box-shadow: 0px 0px 5px 1px #eee;
  box-shadow: 0px 0px 5px 1px #eee;
  border-radius: 5px;
  padding: 1rem;
  margin: 0 2.5rem 2rem 0;
  background: $background;
  &:hover {
    box-shadow: 2px 2px 6px 3px #d2d2d2;
  }
  &__row {
    padding: 0.5rem;
    span {
      display: block;
      text-align: left;
    }
    button {
      background: #82b53f;
      padding: 0.5rem 1rem;
      margin-top: 0.5rem;
      border: 0;
      border-radius: 3px;
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
    }
    .grid {
      display: grid;
      grid-row-gap: 0.5rem;
    }
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.career {
  font-family: $font-family;
  &__header {
    padding: 0 0 1rem 0;
    span {
      font-size: 2rem;
      font-weight: 700;
      &:last-child {
        color: #82b53f;
      }
    }
  }
  &-details {
    display: flex;
    flex-wrap: wrap;
    &__block {
      @include respond(tab) {
        width: 350px;
      }
      @include respond(desktop) {
        width: 395px;
      }

      @include respond(med-desktop) {
        width: 420px;
      }
      @include respond(big-desktop) {
        width: 460px;
      }
      &__header {
        font-size: 1.5rem;
        color: #2580eb;
        font-weight: 500;
        cursor: pointer;
      }
      &__position {
        font-weight: 500;
      }
      .remaining-days {
        color: #888;
      }
      .contract-type {
        font-size: 1.4rem;
        color: #81b43f;
        font-weight: 500;
      }
      .grid {
        .apply-btn {
          cursor: pointer;
        }
      }
    }
  }
  &-additional {
    // padding: 0 2rem;
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .comp-logo {
        img {
          width: 60px;
          // border-radius: 100vw;
        }
      }
      .header-left-info {
        flex: 1;
        .emp-status {
          color: #6f9419;
          font-size: 1rem;
          font-weight: 600;
        }
        .position {
          color: #0e5ab5;
          font-size: 1.6rem;
          font-weight: 600;
        }
        .publish-date {
          color: #2d2d2d;
          font-weight: 500;
        }
        .all-job {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 115px;
          color: #a52a2a;
          font-weight: 500;
          cursor: pointer;
        }
      }
      .header-left-info,
      .header-right-info {
        padding: 1rem;
        span {
          display: block;
          padding: 0.3rem;
        }
      }
      .header-right-info {
        @include right-col();
        .deadline {
          @include deadline;
        }
        .btn-apply {
          @include btn-apply;
        }
      }
    }
    &__body {
      padding: 2rem;
      .body-row {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        .body-col {
          padding: 0 1rem;
          &__header {
            padding-bottom: 1rem;
            span {
              font-weight: 700;
              font-size: 1.3rem;
            }
          }
        }
        .left-col {
          flex: 1;
        }
        .right-col {
          @include right-col();
        }
        .job-info {
          //width: 20vw;
          .body-col__body {
            background: $background;
            padding: 1rem;
            .col-row {
              padding-bottom: 1rem;
              display: flex;
              align-items: center;
              .req-icons {
                padding-right: 1rem;
                img {
                  width: 30px;
                }
              }
              .job-req {
                span {
                  display: block;
                }
                &__header {
                  font-weight: 600;
                }
                &__body {
                  color: #888;
                  font-weight: 500;
                }
              }
            }
          }
        }
        .apply-info {
          float: right;
          width: 100%;
          .deadline {
            @include deadline;
            span:last-child {
              padding: 0.5rem;
            }
          }
          .btn-apply {
            @include btn-apply;
          }
        }
      }
      li {
        list-style: outside;
        margin-left: 30px;
        line-height: 1.6;
      }
    }
    &__footer {
      display: flex;
      align-items: center;
      background: #fafafa;
      padding-left: 3rem;
      .items {
        padding: 0.5rem;
        span {
          font-size: 1.2rem;
          font-weight: 500;
        }
        img {
          width: 45px;
        }
        .social-icon {
          font-size: 2.5rem;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
.company-banner {
  position: relative;

  img {
    width: 100%;
  }
  &__welcome-text {
    position: absolute;
    bottom: 0px;
    left: 10px;
    color: #d9d9d9;
    font-size: 60px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1), 1px 1px 1px rgba(0, 0, 0, 0.5);
  }
}

.btn.success {
}
