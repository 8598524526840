.center-text {
  text-align: center !important;
}

.mb-z {
  margin-bottom: 0 !important;
}
.mt-z {
  margin-top: 0 !important;
}
.mr-z {
  margin-right: 0 !important;
}
.ml-z {
  margin-left: 0 !important;
}

.mb-xxsm {
  margin-bottom: 0.3rem !important;
}
.mb-xsm {
  margin-bottom: 0.5rem !important;
}
.mb-sm {
  margin-bottom: 0.75rem !important;
}
.mb-md {
  margin-bottom: 1.5rem !important;
}
.mb-bg {
  margin-bottom: 2.75rem !important;
}
.mb-hg {
  margin-bottom: 6rem !important;
}

.mt-xsm {
  margin-top: 0.5rem !important;
}
.mt-sm {
  margin-top: 0.75rem !important;
}
.mt-md {
  margin-top: 1.5rem !important;
}
.mt-bg {
  margin-top: 2.75rem !important;
}
.mt-hg {
  margin-top: 6rem !important;
}

.ml-xsm {
  margin-left: 0.5rem !important;
}
.ml-sm {
  margin-left: 0.75rem !important;
}
.ml-md {
  margin-left: 1.5rem !important;
}
.ml-bg {
  margin-left: 2.75rem !important;
}
.ml-hg {
  margin-left: 6rem !important;
}

.mr-xsm {
  margin-right: 0.5rem !important;
}
.mr-sm {
  margin-right: 0.75rem !important;
}
.mr-md {
  margin-right: 1.5rem !important;
}
.mr-bg {
  margin-right: 2.75rem !important;
}
.mr-hg {
  margin-right: 6rem !important;
}

.pad-xxxsm {
  padding: 0.2rem !important;
}
.pad-xxsm {
  padding: 0.3rem !important;
}
.pad-xsm {
  padding: 0.5rem !important;
}
.pad-sm {
  padding: 0.75rem !important;
}
.pad-md {
  padding: 1.5rem !important;
}
.pad-bg {
  padding: 2.75rem !important;
}
.pad-hg {
  padding: 6rem !important;
}

.pb-xsm {
  padding-bottom: 0.5rem !important;
}
.pb-sm {
  padding-bottom: 0.75rem !important;
}
.pb-md {
  padding-bottom: 1.5rem !important;
}
.pb-bg {
  padding-bottom: 2.75rem !important;
}
.pb-hg {
  padding-bottom: 6rem !important;
}

.pt-xsm {
  padding-top: 0.5rem !important;
}
.pt-sm {
  padding-top: 0.75rem !important;
}
.pt-md {
  padding-top: 1.5rem !important;
}
.pt-bg {
  padding-top: 2.75rem !important;
}
.pt-hg {
  padding-top: 6rem !important;
}

.pl-xsm {
  padding-left: 0.5rem !important;
}
.pl-sm {
  padding-left: 0.75rem !important;
}
.pl-md {
  padding-left: 1.5rem !important;
}
.pl-bg {
  padding-left: 2.75rem !important;
}
.pl-hg {
  padding-left: 6rem !important;
}

.pr-xsm {
  padding-right: 0.5rem !important;
}
.pr-sm {
  padding-right: 0.75rem !important;
}
.pr-md {
  padding-right: 1.5rem !important;
}
.pr-bg {
  padding-right: 2.75rem !important;
}
.pr-hg {
  padding-right: 6rem !important;
}

.wd-10 {
  width: 10% !important;
}
.wd-15 {
  width: 15% !important;
}
.wd-20 {
  width: 20% !important;
}
.wd-25 {
  width: 25% !important;
}
.wd-30 {
  width: 30% !important;
}
.wd-40 {
  width: 40% !important;
}
.wd-50 {
  width: 50% !important;
}
.wd-60 {
  width: 60% !important;
}
.wd-70 {
  width: 70% !important;
}
.wd-80 {
  width: 80% !important;
}
.wd-90 {
  width: 90% !important;
}
.wd-100 {
  width: 100% !important;
}

.normal-weight {
  font-weight: 400;
}
.bold-500 {
  font-weight: 500;
}
.bold-600 {
  font-weight: 600;
}
.bold-700 {
  font-weight: 700;
}
.bold-800 {
  font-weight: 800;
}
.bold-900 {
  font-weight: 900;
}
.bold {
  font-weight: bold;
}

.text-black {
  color: #2c2c2c !important;
}
.text-red {
  color: #{$color-red} !important;
}
.text-green {
  color: #{$color-green} !important;
}
.text-yellow {
  color: #{$color-yellow} !important;
}
.text-primary {
  color: #{$rigo-primary-color} !important;
}
.text-orange {
  color: #{$color-orange} !important;
}
.text-blue {
  color: #{$color-blue} !important;
}
.text-blue2 {
  color: #{$color-blue-2} !important;
}
.text-light {
  color: #a4a4a4;
}
.text-grey {
  color: #{$color-grey} !important;
}
.text-pink {
  color: #{$color-pink} !important;
}
.text-dark-blue {
  color: #224070 !important;
}
.text-bold {
  font-weight: 600;
}

.text-sm {
  font-size: 12px;
}
.text-md {
  font-size: 14px;
}
.text-16 {
  font-size: 16px;
}
.text-15 {
  font-size: 15px;
}
.text-bg {
  font-size: 17px;
}
.text-xbg {
  font-size: 20px;
}

.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}

.space-between {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.flex {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.flex-reverse {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.flex-column {
  display: flex;
  flex-direction: column !important;
}
.flex-start {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.column-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.grid {
  display: grid;
  &-half {
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
  }
}

.right {
  float: right;
}
.left {
  float: left;
}
.display-block {
  display: block;
}
.display-visit {
  display: block;
  height: 0;
  width: 0;
}

.items-center {
  align-items: center !important;
}
.items-start {
  align-items: flex-start !important;
}
.items-end {
  align-items: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}
.justify-end {
  justify-content: flex-end !important;
}
.justify-start {
  justify-content: flex-start !important;
}
.justify-between {
  justify-content: space-between !important;
}
.cursor-pointer {
  cursor: pointer;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-blue {
  background-color: $color-blue;
  color: #fff;
}
.bg-grey {
  background-color: $color-grey;
  color: #fff;
}

.error-color {
  color: #{$color-red} !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}

.link-color {
  color: $link-color !important;
  fill: $link-color !important;
  cursor: pointer !important;
}

.active {
  font-weight: 600;
  text-decoration: underline;
}

.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.no-content {
  font-size: 12px;
  font-style: italic;
  color: #555;
}
