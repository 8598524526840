.app {
  width: 100%;
  height: 100%;
  font-family: "Roboto", "Mukta", "Helvetica", "Arial", "sans-serif";
  font-weight: 400;
  font-size: $default-fontSize;
  color: #444;

  &-block {
    display: flex;
    flex-direction: column;
    position: relative;
    // min-height: 100vh;
  }
}
.app-container {
  // padding: 10px 10px 10px 15px;
  display: flex;
  justify-content: center;
  min-height: 75vh;
  // align-items: center;
  // background: #f1f5f8;
  // padding-bottom: 100px;
}
.disabled {
  cursor: not-allowed !important;
}

// .daraz-orders-actions {
//   & > div {
//     margin: 0 10px;
//   }
//   button {
//     max-height: 32px;
//   }
// }
.admin-block {
  padding: 10px;
}
.admin-title {
  @include heading(3, #353f5a);
}

.common {
  &-container {
    @include containerWidth;
    justify-content: center;
    align-items: center;

    // padding: 5px 10px;
    // background-color: #f1f5f8;
    // background-color: #f1f5f8;
    // min-height: calc(100vh - 88px);
    // height: 100%;

    h2 {
      @include heading(2, #1b5693);
    }
    .tabs {
      background-color: #f9fbfe;

      .tab-active {
        // background-color: #0072C6;
        color: #fff;
        background-image: linear-gradient(180deg, #2680eb, #0066ff);
      }
      .tab-last {
        border-top-right-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
        color: #fff;
        background-image: linear-gradient(180deg, #2680eb, #0066ff);
      }
      .tab-not_allowed {
        cursor: not-allowed;
      }
      // .tab-tooltip {
      //   position: relative;
      //   display: inline-block;
      //   border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
      // }
      .tab-tooltip {
        position: relative;
        display: inline-block;
        // border-bottom: 1px dotted black;
      }
      .tab-tooltip .tab-tooltiptext {
        visibility: hidden;
        width: 150px;
        background-color: rgba(40, 40, 40, 0.9);
        color: #fff;
        // color: $color-red;
        text-align: center;
        padding: 0.25rem;
        border-radius: 6px;
        font-size: $default-fontSize-sm;

        /* Position the tooltip text - see examples below! */
        position: absolute;
        top: 40;
        left: 0;
        z-index: 1;
      }
      .tab-tooltip:hover .tab-tooltiptext {
        visibility: visible;
      }

      &-content {
        padding: 0;
      }
      .tab-container {
        display: flex;
        flex-direction: column;
        position: relative;
        .loader {
          position: absolute;
        }
      }
      .tab-footer {
        background-color: #f5f7fa;
        padding: 1rem 1.5rem;
        // padding: 1.5rem;
      }
      .tab-icon {
        margin-right: 0.25rem;
      }
      .tab-done {
        color: #fff;
        background-image: linear-gradient(180deg, #2680eb, #0066ff);
        position: relative;
        &::after {
          content: "";
          top: 50%;
          right: 0;
          width: 0.06rem;
          height: 60%;
          background-color: rgba(255, 255, 255, 0.3);
          position: absolute;
          z-index: 111111111;
          border-radius: 6px;
          transform: translateY(-50%);
        }
      }
      &-list {
        background-color: #fff;
        // border-bottom: 3px solid #0072C6;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        border-bottom: none;
        &__item {
          // border-top-left-radius: 4px;
          // border-top-right-radius: 4px;
          border-bottom: none;
          margin-right: 0px;
        }
      }
      &-content {
        border: 1px solid #bcccdc;
      }
    }
  }
  &-heading {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    @include heading(3);
  }
}
#react-doc-viewer {
  #header-bar {
    display: none;
  }
}

.file-show {
  text-align: center;

  &__image {
    max-width: 1000px;
    margin: 0 auto;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}

.documents {
  text-align: center;
  padding: 15px;
  &-image {
    max-width: 800px;
    margin: 0 auto;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}

.already-link {
  padding: 20px 0;
  span {
    color: #224070;
    font-size: 14px;

    a {
      color: #0066ff;
      margin: 0 3px;
      &:hover {
        color: #2680eb;
      }
    }
  }
}

.container {
  max-width: 1360px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
.cover-banner {
  width: 100%;
  height: 320px;
  @media (max-width: 700px) {
    height: 285px;
  }
  position: relative;
  &:after {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.3785889355742297) 0%, rgba(0, 0, 0, 0) 100%);
    content: "";
  }
}
.cover-image {
  height: 100%;
  width: 100%;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.cover-features {
  position: absolute;
  top: 0;
  max-width: 1360px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  z-index: 1;
}
.welcome-container {
  background: #fff;
  padding: 25px 0;
  margin-bottom: 25px;
}
.welcome-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 18px;
  line-height: 1.6;
  font-weight: 400;
  color: #2c2c2c;
  p {
  }
}
.logo-cover {
  background: #fff;
  border-radius: 0 0 5px 5px;
  padding: 10px 15px;
  width: 160px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 130px;
    height: auto;
  }
}
.page-title {
  color: #2c2c2c;
  font-size: 34px;
  margin-bottom: 10px;
  line-height: 1;
}
.career {
  margin-top: 40px;
  &-details {
    margin: 0 -15px;
  }
}
.careers {
  width: 50%;
  padding: 0 15px;
  margin-bottom: 30px;
  @media (max-width: 700px) {
    width: 100%;
  }
}
.card {
  padding: 25px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #e5e9ed;
  transition: 0.3s ease;
  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.045);
    transform: translateY(-2px);
  }
  &-title {
    font-size: 24px;
    color: #172b4c;
    font-weight: 700;
    margin-bottom: 10px;
    line-height: 1;
  }
}
.deadline {
  color: #ff5c5c;
}
.deadline__value {
  padding-left: 5px;
}
.jobdesc {
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  &.deadline {
    font-size: 14px;
    .information {
      display: flex;
      color: #2c2e2d;
      margin-bottom: 5px;
      span {
        margin-right: 10px;
      }
    }
  }
  &.secondary {
    .information {
      display: flex;

      font-weight: 400;

      color: #2c2c2c;
      .info-label {
        min-width: 150px;
      }
    }
  }
  &.primary {
    .information {
      color: #172b4c;
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.information {
  margin-bottom: 5px;
  font-size: 16px;
}

.apply-btn {
  padding: 11px 35px;
  border-radius: 3px;
  background: #2196f3;
  color: #fff;
  font-size: 16px;
  border: none;
  display: inline-block;
  transition: 0.3s ease;
  cursor: pointer;
  &:hover {
    background: #287abb;
  }
}

.career-detail {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -25px;
}
.career-leftcol {
  width: 65%;
  padding: 0 25px;
  @media (max-width: 700px) {
    width: 100%;
  }
}
.career-rightcol {
  width: 35%;
  padding: 0 25px;
  @media (max-width: 700px) {
    width: 100%;
  }
}

.box {
  &.centered {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-bottom: 15px;
    .information {
      text-align: center;
      font-size: 16px;
    }
    .deadline {
      svg {
        margin-right: 5px;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      text-align: center;
      margin-bottom: 10px;
    }
  }
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  &.share {
    padding: 20px;
    text-align: center;
    p {
      font-size: 19px;
      margin-bottom: 10px;
    }
    .items {
      display: inline-block;
      width: 48px;
      height: 48px;
      svg {
        width: 100%;
        height: 100%;
      }
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
  &.enddate {
    text-align: center;
    font-size: 16px;
    padding: 20px;
    .btn {
      margin: 0 auto;
    }
    .deadline {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }
  background: #fff;
  &-header {
    font-size: 20px;
    font-weight: 600;
    color: #172b4c;
    background: #e5e9ed;
    padding: 16px 19px;
  }
  &-body {
    padding: 16px 19px;
    font-size: 20px;
    &.ck-body {
      li {
        list-style: disc;
        list-style-position: inside;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
      }
    }
  }
}

.additional-detail {
  display: flex;
  flex-wrap: wrap;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
.req-icons {
  width: 25px;
  img {
    max-width: 100%;
  }
}
.job-req {
  padding: 0 0 0 15px;

  font-size: 16px;
  > span {
    display: block;
  }
  &__header {
    font-weight: 500;
    color: #172b4c;
    margin-bottom: 5px;
  }
  &__body {
    font-weight: 400;
    color: #2c2c2c;
  }
}

.btn {
  > svg {
    margin-left: 10px;
  }
  &.success {
    background: #3da643;
    color: #fff;
    font-size: 16px;
  }
  &.btn-apply {
    width: 275px;
    height: 48px;
    line-height: 1;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.detail-header {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -25px;
}
.header-left {
  width: 65%;
  padding: 0 25px;
  @media (max-width: 700px) {
    width: 100%;
  }
  display: flex;
  margin-bottom: 25px;
  margin-top: 25px;
  .comp-logo {
    width: 180px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    background: #fff;
    > img {
      max-width: 100%;
      height: auto;
    }
  }
  .header-left-info {
    padding-left: 35px;
  }
}
.header-right {
  width: 35%;
  padding: 0 25px;
  margin-bottom: 25px;
  margin-top: 25px;
  @media (max-width: 700px) {
    width: 100%;
  }
}

.header-left-info {
  color: #172b4c;
  font-size: 400;
}
.headerinfo-footer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  .otherinfo {
    padding-right: 25px;

    @media (max-width: 700px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}
.emp-status {
  color: #3da643;
  font-size: 21px;
  margin-bottom: 5px;
}
.position {
  font-size: 31px;
  margin-bottom: 10px;
  font-weight: 500;
}
.otherinfo {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
  display: flex;
  align-items: center;

  span {
    padding-left: 10px;
    display: inline-block;
  }
  svg {
    margin-right: 5px;
  }
}
.publisheddate {
  margin-bottom: 12px;
}

.careerdetail-header {
  background: #005897;
  padding: 40px 20px;
  color: #fff;
  @media (max-width: 700px) {
    padding: 25px 20px;
  }
  position: relative;
  h1 {
    font-weight: 500;
    font-size: 30px;
    text-align: center;
    @media (max-width: 700px) {
      text-align: left;
    }
  }
}
.backbtn {
  text-transform: uppercase;
  font-size: 18px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  @media (max-width: 700px) {
    left: auto;
    right: 30px;
  }
  cursor: pointer;
  transition: 0.3s ease;
  &:hover {
    text-decoration: underline;
  }
  svg {
    margin-right: 15px;
  }
}

.detail-header {
  margin-top: 30px;
}
